import {AxiosError} from 'axios';
import {useMutation} from '@tanstack/react-query';

import {api} from '@eksab/api';

import {useInvalidateMyCards} from './useMyCardsQuery';

export function useDeletePaymentCardMutation() {
  const invalidateMyCards = useInvalidateMyCards();

  return useMutation<void, AxiosError, {token: string}>(({token}) => api.delete(`v2/payments/user-cards/${token}`), {
    onSuccess: () => invalidateMyCards(),
  });
}
