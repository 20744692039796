import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useInvalidateMe} from '@eksab/hooks';

import {useInvalidateCompetition} from './useCompetitionQuery';
import {useInvalidateCompetitions} from './useCompetitionsQuery/useCompetitionsQuery';
import {useInvalidateMyCompetitions} from '@eksab/features/profile/hooks';

type Params = string;

type Return = {
  data: unknown;
};

const joinCompetition = (slug: Params) =>
  api.post<Params, AxiosResponse<Return>>(`v2/competitions/${slug}/join`).then((res) => res.data.data);

export function useCompetitionJoin(options?: UseMutationOptions<Return['data'], AxiosError, Params>) {
  const invalidateMe = useInvalidateMe();
  const invalidateCompetition = useInvalidateCompetition();
  const invalidateCompetitions = useInvalidateCompetitions();
  const invalidateMyCompetitions = useInvalidateMyCompetitions();

  return useMutation<Return['data'], AxiosError, Params>((slug) => joinCompetition(slug), {
    ...options,
    onSuccess: (...params) => {
      fbq?.('track', 'join_competition');
      options?.onSuccess?.(...params);
      invalidateMe();
      invalidateCompetition(params[1], 'user');
      invalidateCompetitions();
      invalidateMyCompetitions();
    },
  });
}
