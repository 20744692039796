import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import * as cache from '@eksab/cache';
import {useInvalidateMe} from '@eksab/hooks';

interface RequestParams {
  name: string;
  username?: string;
  email?: string;
  phone_number?: string;
  profile_picture?: {data: string; filename: string};
}

export const useChangeInfoMutation = (options?: UseMutationOptions<Me, AxiosError, RequestParams>) => {
  const invalidateMe = useInvalidateMe();

  return useMutation<Me, AxiosError, RequestParams>(
    (params) => api.post<RequestParams, AxiosResponse<{data: Me}>>('v2/profile', params).then((res) => res.data.data),
    {
      ...options,
      onSuccess: async (...params) => {
        try {
          invalidateMe();
          const [data] = params;
          cache.setMe(data);

          options?.onSuccess?.(...params);
        } catch (e) {
          console.error(e);
        }
      },
    },
  );
};
