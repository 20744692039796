import {api} from '@eksab/api';
import {UserChallenge} from '@eksab/features/trivia/types';
import {useInfiniteQuery} from '@tanstack/react-query';
import {useRouter} from 'next/router';

type Response = {
  data: UserChallenge[];
  meta: {current_page: number; per_page: number; total: number};
};

const queryKey = (locale: Locale) => ['my-trivia', locale];
const queryFn = ({pageParam = 1}) =>
  api('v1/profile/challenges', {params: {page: {page: pageParam}}}).then((res) => res.data as Response);

export function useMyTriviaChallengesInfinteQuery() {
  const locale = useRouter().locale;
  return useInfiniteQuery(queryKey(locale), queryFn, {
    getNextPageParam: ({meta: {current_page, per_page, total}}) =>
      total > per_page * current_page ? current_page + 1 : undefined,
  });
}

useMyTriviaChallengesInfinteQuery.queryKey = queryKey;
useMyTriviaChallengesInfinteQuery.queryFn = queryFn;
