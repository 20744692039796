import {AxiosError} from 'axios';
import {useMutation} from '@tanstack/react-query';

import {api} from '@eksab/api';

import {PrizeRedemptionPersonalDetails} from '../types';

export type Forms = {
  name: string;
  file: string;
}[];

interface GenerateRedemptionFormsParams
  extends Omit<PrizeRedemptionPersonalDetails, 'national_id_scans' | 'national_id'> {
  documents: {national_id: string};
  user_prize_id: number;
}

type ErrorResponse = {
  message: string;
  errors: Partial<
    Record<keyof Omit<GenerateRedemptionFormsParams, 'documents'>, [string]> & {'documents.national_id': string[]}
  >;
};

export function useGenerateRedemptionFormsMutation() {
  return useMutation<Forms, AxiosError<ErrorResponse>, GenerateRedemptionFormsParams>((params) =>
    api.post('v2/prize-redemption/generate-redemption-forms', params).then((res) => res.data.data),
  );
}
