import {useState} from 'react';
import type {AxiosError} from 'axios';
import {useMutation, type UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';

interface UploadFileMutationResponse {
  id: string;
  url: string;
  filename: string;
}

export function useUploadFileMutation(options?: UseMutationOptions<UploadFileMutationResponse, AxiosError, File>) {
  const [progress, setProgress] = useState(0);

  const mutation = useMutation<UploadFileMutationResponse, AxiosError, File>(
    async (file) => {
      const formData = new FormData();
      formData.append('file', file);

      const res = await api.post('v1/admin/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (e) => setProgress(Math.round((e.loaded * 100) / e.total)),
      });

      return res.data.data;
    },
    {
      ...options,
      onError: (...params) => {
        setProgress(0);

        options?.onError?.(...params);
      },
    },
  );

  return {...mutation, progress};
}
