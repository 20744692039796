import {useId} from 'react';
import {useRouter} from 'next/router';
import {Flex, FlexProps, Icon, Text} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {getLanguage} from '@eksab/util';

import type {Competition} from '../../types';

interface CompetitionTierProps extends FlexProps {
  tier: Competition['tier'];
}

export const CompetitionTier = ({tier, ...props}: CompetitionTierProps) => {
  const gradientID = useId();
  const {locale} = useRouter();

  const t = useTranslate();

  const tierGradient = tierGradients[tier];
  const transformDirection = getLanguage(locale) === 'ar' ? -1 : 1;

  return (
    <Flex
      align="center"
      pos="relative"
      isolation="isolate"
      fontSize="xs"
      fontWeight="800"
      fontStyle="italic"
      color={tier === 'amateur' || tier === 'flex' ? 'white' : 'black'}
      {...props}
    >
      <Icon width="130px" height="16px" viewBox="0 0 130 16" fill="none" transform="auto" scaleX={transformDirection}>
        <path
          d="M11.8994 0.768711C12.2785 0.283553 12.8598 0 13.4755 0H119.757C121.441 0 122.371 1.95255 121.311 3.25986L111.578 15.2599C111.198 15.7281 110.627 16 110.024 16H4.10049C2.43419 16 1.4986 14.0818 2.52444 12.7687L11.8994 0.768711Z"
          fill={`url(#${gradientID})`}
        />
        <path d="M118 0H130L118.5 16H105L118 0Z" fill="#101010" />
        <path d="M113 0H118.5L106 16H100L113 0Z" fill="#101010" />
        <path d="M109 0H112.5L99.5 16H96L109 0Z" fill="#101010" />
        <path d="M105 0H108L95 16H92L105 0Z" fill="#101010" />
        <path d="M100 0H103L90 16H87L100 0Z" fill="#101010" />

        <defs>
          <linearGradient
            id={gradientID}
            x1="-6.92652e-07"
            y1="7.99999"
            x2="116"
            y2="7.99999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={tierGradient.color1} />
            <stop offset="1" stopColor={tierGradient.color2} />
          </linearGradient>
        </defs>
      </Icon>

      <Text
        as="span"
        color="inherit"
        pos="absolute"
        insetStart="35%"
        transform="auto"
        translateX={`calc(${-transformDirection} * 35%)`}
        zIndex="1"
        filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      >
        {t(`competition.tier.${tier}`)}
      </Text>
    </Flex>
  );
};

const tierGradients: Record<Competition['tier'], {color1: string; color2: string}> = {
  training: {color1: '#90FF69', color2: '#E9E5E1'},
  amateur: {color1: '#8518f3', color2: '#02fb57'},
  pro: {color1: '#D79314', color2: '#FFE1AA'},
  flex: {color1: '#ff0000', color2: '#ff6347'},
};
