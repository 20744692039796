import type {Player, Players} from './types';

type Substitutes = [Player | null, Player | null, Player | null, Player | null];

export const parsePlayers = (players: Player[], formation: Formation): [Players, Substitutes] => {
  const lineupPlayers = players.filter(({is_substitute}) => !is_substitute);
  const substitutePlayers = players.filter(({is_substitute}) => is_substitute) as Substitutes;

  const [noOfDefenders, noOfMidfielders, noOfAttackers] = formation.split('-').map(Number);

  const goalKeeper = lineupPlayers.splice(0, 1);
  const defenders = lineupPlayers.splice(0, noOfDefenders);
  const midfielders = lineupPlayers.splice(0, noOfMidfielders);
  const attackers = lineupPlayers.splice(0, noOfAttackers);

  return [[goalKeeper, defenders, midfielders, attackers], substitutePlayers];
};

export const getGameKey = (gameId: number | string) => `prediction/${gameId}`;
