import {AxiosError} from 'axios';
import {useMutation} from '@tanstack/react-query';

import {api} from '@eksab/api';

import {WalletIssuer, PrizeRedemptionPersonalDetails} from '../types';

type Params = Omit<PrizeRedemptionPersonalDetails, 'national_id_scans' | 'national_id'> & {
  redemption_method:
    | {
        name: 'E-Wallet';
        options: {
          phone_number: string;
          wallet: {issuer: WalletIssuer};
        };
      }
    | {
        name: 'Coins';
      }
    | {
        name: 'Manual';
      };
} & {
  documents: {
    national_id_scans: PrizeRedemptionPersonalDetails['national_id_scans'] | null;
    national_id: string;
  };
};

export function useRedemptionRequestMutation() {
  return useMutation<
    void,
    AxiosError<{
      errors: Partial<Record<keyof Omit<PrizeRedemptionPersonalDetails, 'national_id_scans'>, [string]>>;
      message: string;
    }>,
    Params
  >((params) => api.post('v2/prize-redemption/submit-redemption-request', params));
}
