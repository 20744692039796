import {useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {useRouter} from 'next/router';

import {api} from '@eksab/api';
import {useAccessToken, useQuery} from '@eksab/hooks';

import type {SeasonGame} from './useSeasonGamesQuery';
import type {ScoreBreakdown, Bet} from '../types';

type Params = {
  game: number | string;
};

export type Game = {
  id: number;
  score_breakdown?: ScoreBreakdown;
  bets: Bet[];
  game_score_bet?: {
    id: number;
    current_user_prediction?: {value: {away_goals: number; home_goals: number}};
  };
  match: MatchT;
  state: SeasonGame['state'];
  current_user_points: SeasonGame['current_user_points'];
};

type QueryContext = 'viewer' | 'participant';
const queryKey = (params: Params, context: QueryContext, isLoggedIn: boolean) => [
  'prediction/game',
  `${params.game}`,
  context,
  isLoggedIn,
];
const queryFn = (params: Params, isLoggedIn: boolean) =>
  api.get<{data: Game}>(`v2/games/${params.game}${isLoggedIn ? '/auth' : ''}`).then((res) => ({
    ...res.data.data,
    hasMadePredictions: !!res.data.data?.bets.some((bet) => bet.current_user_prediction),
  }));

export function useGameQuery(
  params: Params,
  context: QueryContext,
  options?: UseQueryOptions<Game & {hasMadePredictions: boolean}>,
) {
  const isLoggedIn = !!useAccessToken()?.data;
  return useQuery<Game & {hasMadePredictions: boolean}>(
    queryKey(params, context, isLoggedIn),
    () => queryFn(params, isLoggedIn),
    options,
  );
}

export function useRefetchGameQuery() {
  const queryClient = useQueryClient();
  const locale = useRouter().locale ?? 'en';
  const isLoggedIn = !!useAccessToken()?.data;
  const refetchGameQuery = (gameId: number) =>
    queryClient.refetchQueries([...queryKey({game: gameId}, 'participant', isLoggedIn), locale]);
  return refetchGameQuery;
}

export function usePrefetchGameQuery(context: QueryContext) {
  const queryClient = useQueryClient();
  const locale = useRouter().locale ?? 'en';
  const isLoggedIn = !!useAccessToken()?.data;
  const prefetchGameQuery = (gameId: number) =>
    queryClient.prefetchQuery([...queryKey({game: gameId}, context, isLoggedIn), locale], () =>
      queryFn({game: gameId}, isLoggedIn),
    );
  return prefetchGameQuery;
}
