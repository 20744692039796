import {api} from '@eksab/api';

import {AxiosError} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {Prize} from '../types';

const queryFn = () => api.get('v2/me/prizes').then((res) => res.data.data);
const queryKey = ['my-prizes'];
export function useMyPrizesQuery() {
  return useQuery<Prize[], AxiosError>(queryKey, queryFn);
}

useMyPrizesQuery.queryKey = queryKey;
useMyPrizesQuery.queryFn = queryFn;
