import {AxiosError} from 'axios';
import {UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useAccessToken, useQuery} from '@eksab/hooks';

import type {Bet} from '../types';
import {SeasonGame} from './useSeasonGamesQuery';

type Params = {
  participantID: number | undefined;
  seasonId: number | undefined;
};

export type Response = {
  data: (Omit<SeasonGame, 'game_score_bet' | 'bets'> & {
    game_score_bet: Omit<SeasonGame['game_score_bet'], 'current_user_prediction'> & {
      participant_prediction: Exclude<SeasonGame['game_score_bet'], undefined>['current_user_prediction'];
    };
    bets: (Omit<Bet, 'current_user_prediction'> & {participant_prediction: Bet['current_user_prediction']})[];
  })[];
};

const queryKey = ({seasonId, participantID}: Params) => ['participant-predictions', seasonId, participantID];

const queryFn = ({seasonId, participantID}: Params, isLoggedIn: boolean) =>
  api
    .get<Response>(`v2/games${isLoggedIn ? '/auth' : ''}`, {
      params: {'for-participant': participantID, season: seasonId},
    })
    .then((res) => res.data.data);

export const useParticipantPredictionsQuery = (
  params: Params,
  options?: UseQueryOptions<Response['data'], AxiosError>,
) => {
  const isLoggedIn = !!useAccessToken()?.data;

  return useQuery<Response['data'], AxiosError>(queryKey(params), () => queryFn(params, isLoggedIn), {
    ...options,
    enabled: !!params.participantID && !!params.seasonId && (options?.enabled ?? true),
  });
};

useParticipantPredictionsQuery.queryFn = queryFn;
useParticipantPredictionsQuery.queryKey = queryKey;
