import {AxiosError} from 'axios';
import {useQueryClient, UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks';

import type {Player} from '../types';

export type CheckSameLineupQueryResponse =
  | {
      lineup_flag: true;
      team_setup: {
        formation: Formation;
        players: Player[];
      };
    }
  | {
      lineup_flag: false;
      team_setup: null;
    };

const queryKey = (competitionID: string) => ['check-same-lineup', competitionID];

const queryFn = (competitionID: string) =>
  api.get(`v2/competitions/${competitionID}/check-lineup`).then((res) => res.data.data);

export const useCheckSameLineupQuery = (
  competitionID: string,
  options?: UseQueryOptions<CheckSameLineupQueryResponse, AxiosError>,
) =>
  useQuery<CheckSameLineupQueryResponse, AxiosError>(queryKey(competitionID), () => queryFn(competitionID), {
    ...options,
    enabled: !!competitionID && (options?.enabled ?? true),
  });

useCheckSameLineupQuery.queryFn = queryFn;
useCheckSameLineupQuery.queryKey = queryKey;

export function useInvalidateCheckSameLineupQuery() {
  const queryClient = useQueryClient();
  return (competitionID: string) =>
    queryClient.invalidateQueries<CheckSameLineupQueryResponse>(queryKey(competitionID));
}
