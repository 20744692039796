import {AxiosError} from 'axios';
import {UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks';

import {parsePlayers} from '../helpers';
import type {Player} from '../types';

type ID = number | undefined;

type Params = {
  participantID: ID;
  competitionID: ID;
};

export type TeamSetup = {
  name: string;
  formation: Formation;
  players: Player[];
} | null;

type MappedTeamSetup = {
  lineupPlayers: ReturnType<typeof parsePlayers>[0];
  substitutes: ReturnType<typeof parsePlayers>[1];
} | null;

const queryKey = ({competitionID, participantID}: Params) => ['team-setup', competitionID, participantID];

const queryFn = ({competitionID, participantID}: Params) =>
  api.get(`v2/competitions/${competitionID}/team-setup?user=${participantID}`).then((res) => res.data.data);

export const useTeamSetupQuery = (params: Params, options?: UseQueryOptions<TeamSetup, AxiosError, MappedTeamSetup>) =>
  useQuery<TeamSetup, AxiosError, MappedTeamSetup>(queryKey(params), () => queryFn(params), {
    ...options,
    select: (teamSetup) => {
      if (!teamSetup) return null;

      const [lineupPlayers, substitutes] = parsePlayers(teamSetup.players, teamSetup.formation);

      return {lineupPlayers, substitutes};
    },
    enabled: !!params.competitionID && !!params.participantID && (options?.enabled ?? true),
  });

useTeamSetupQuery.queryFn = queryFn;
useTeamSetupQuery.queryKey = queryKey;
