import {AxiosError} from 'axios';
import {useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks/useQuery';
import type {MyPower} from '@eksab/features/store/badges/types';
import {useAccessToken} from '@eksab/hooks';

export type MyPowersFilter = {key?: MyPower['power']['key']; 'is-expired'?: boolean};

const queryKey = (filters?: MyPowersFilter) => ['my-powers', filters?.key, filters?.['is-expired']];

const queryFn = (filters?: MyPowersFilter) => () =>
  api.get<{data: MyPower[]}>('v1/user-powers', {params: filters}).then((res) => res.data.data);

export const useMyPowersQuery = (filters?: MyPowersFilter, options?: UseQueryOptions<MyPower[], AxiosError>) => {
  const token = useAccessToken().data;

  return useQuery<MyPower[], AxiosError>(queryKey(filters), queryFn(filters), {
    ...options,
    enabled: !!token,
  });
};
useMyPowersQuery.queryKey = queryKey;
useMyPowersQuery.queryFn = queryFn;

export function useInvalidateMyPowers() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries<MyPower[]>(['my-powers']);
}
