import {AxiosError} from 'axios';
import {useInfiniteQuery, UseInfiniteQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useAccessToken} from '@eksab/hooks';

import type {RankingType} from '../ranking/const';
import type {Participant} from '../types';

type RankingPage = {
  data: Participant[];
  meta: {
    current_page: number;
    last_page: number;
    from: number;
    to: number;
    per_page: number;
    total: number;
  };
};

interface UseRanksQueryParams {
  seasonId: number | undefined;
  type?: RankingType;
}

const queryKey = ({seasonId, type = 'all'}: UseRanksQueryParams, isLoggedIn = false) => [
  'ranking',
  seasonId,
  type,
  isLoggedIn ? 'user' : 'guest',
];

const queryFn = ({seasonId, type = 'all'}: UseRanksQueryParams, page = 1, isLoggedIn = false) =>
  api
    .get(`rankings${isLoggedIn ? '/auth' : ''}`, {
      params: {
        'season-id': seasonId,
        page,
        type,
      },
    })
    .then((res) => res.data);

export const useRanksQuery = (
  params: UseRanksQueryParams,
  options?: UseInfiniteQueryOptions<RankingPage, AxiosError>,
) => {
  const isLoggedIn = useAccessToken().isSuccess;

  return useInfiniteQuery<RankingPage, AxiosError>(
    queryKey(params, isLoggedIn),
    ({pageParam}) => queryFn(params, pageParam, isLoggedIn),
    {
      ...options,
      enabled: !!params.seasonId && (params.type === 'friends' ? isLoggedIn : true) && (options?.enabled ?? true),
      getNextPageParam: ({meta: {current_page = 1, last_page = 100} = {}}, pages) =>
        pages.length < last_page ? current_page + 1 : undefined,
    },
  );
};

useRanksQuery.queryFn = queryFn;
useRanksQuery.queryKey = queryKey;
