import {AxiosError} from 'axios';
import {UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useAccessToken, useQuery} from '@eksab/hooks';

import type {Participant} from '../types';

interface MyRankingQueryParams {
  seasonId: number | undefined;
}

const queryKey = ({seasonId}: MyRankingQueryParams) => ['my-ranking', seasonId];

const queryFn = ({seasonId}: MyRankingQueryParams) =>
  api
    .get(`rankings/mine`, {
      params: {
        'season-id': seasonId,
      },
    })
    .then((res) => res.data.data ?? null);

export const useMyRankingQuery = (params: MyRankingQueryParams, options?: UseQueryOptions<Participant, AxiosError>) => {
  const isLoggedIn = useAccessToken().isSuccess;

  return useQuery<Participant, AxiosError>(queryKey(params), () => queryFn(params), {
    ...options,
    enabled: !!params.seasonId && isLoggedIn && (options?.enabled ?? true),
  });
};

useMyRankingQuery.queryFn = queryFn;
useMyRankingQuery.queryKey = queryKey;
