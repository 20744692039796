import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';

interface RequestParams {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export const useChangePasswordMutation = (options?: UseMutationOptions<true, AxiosError, RequestParams>) =>
  useMutation<true, AxiosError, RequestParams>(
    (params) =>
      api.post<RequestParams, AxiosResponse>('v2/profile/change-password', params).then((res) => res.data.data),
    options,
  );
