import {
  Box,
  chakra,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  IconProps,
  Switch,
  Text,
  useBreakpointValue,
  Center,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  ButtonProps,
} from '@chakra-ui/react';
import {useIntl} from 'react-intl';
import * as _Checkbox from '@radix-ui/react-checkbox';

import {useLeaguesQuery, useMeQuery, useRegionSettings} from '@eksab/hooks';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';

import {useTranslate} from '@eksab/i18n';
import {MAX_COINS, RangeSlider, MAX_ENTRY_FEE, FiltersButton} from '@eksab/components';
import {XIcon} from '@eksab/assets/icons';

export type CompetitionFilters = {
  type: string[];
  mine: true | undefined;
  featured: true | undefined;
  entry_fees: [number, number];
  league_ids: number[];
  matches: number[];
};

type FiltersProps = {
  count: number;
  initialState: CompetitionFilters;
  onSubmit: (state: CompetitionFilters) => void;
} & (
  | {
      filterByType?: undefined;
      type: 'predictions' | 'fantasy';
    }
  | {
      filterByType: true;
      type?: undefined;
    }
) &
  Omit<ButtonProps, 'onSubmit' | 'type'>;

export function useFiltersState(type?: 'predictions' | 'fantasy') {
  const filtersState = useState(emptyState(type));
  return [
    ...filtersState,
    // count of filters applied
    (filtersState[0].featured || filtersState[0].mine ? 1 : 0) +
      (filtersState[0].league_ids.length ? 1 : 0) +
      (filtersState[0].matches.length ? 1 : 0) +
      (type ? 0 : filtersState[0].type.length) +
      (filtersState[0].entry_fees[0] === 0 && filtersState[0].entry_fees[1] === MAX_ENTRY_FEE ? 0 : 1),
  ] as const;
}

const Checkbox = {Root: chakra(_Checkbox.Root), Indicator: chakra(_Checkbox.Indicator)};

const hideScrollBar = {
  '::-webkit-scrollbar': {
    display: 'none',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
};

const emptyState = (type?: 'predictions' | 'fantasy') =>
  ({
    league_ids: [],
    type: type ? [type] : [],
    featured: undefined,
    mine: undefined,
    matches: [],
    entry_fees: [0, MAX_ENTRY_FEE],
  }) as CompetitionFilters;

useFiltersState.emptyState = emptyState;

export function Filters({type, count, initialState, filterByType, onSubmit, ...props}: FiltersProps) {
  const [state, setState] = useState<CompetitionFilters>(emptyState(type));

  useEffect(() => {
    // If league selection changes, reset matches selection
    setState((old) => ({...old, matches: []}));
  }, [state.league_ids.length]);

  const placement = useBreakpointValue(['bottom', null, null, 'end']) as 'bottom' | 'end';

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setState(initialState);
  }, [open, initialState]);

  const t = useTranslate();

  return (
    <>
      <FiltersButton {...props} open={open} onClick={() => setOpen(true)} count={count} />

      <Drawer isOpen={open} onClose={() => setOpen(false)} placement={placement} size="sm">
        <DrawerOverlay />
        <DrawerContent py="6" h={['90%', null, null, '100%']}>
          <DrawerHeader py={0} px="4">
            <Flex justify="space-between" mb="2.5">
              <Text fontWeight="600" fontSize="24">
                {t('fantasy.lineup.filters')}
              </Text>
              <IconButton
                minW="unset"
                onClick={() => setOpen(false)}
                h="unset"
                variant="unstyled"
                aria-label="Close"
                icon={<XIcon />}
              />
            </Flex>
          </DrawerHeader>
          <Body state={state} filterByType={filterByType} setState={setState} />
          <DrawerFooter px="4" py="0">
            <Flex mt="18px">
              <Button
                variant="unstyled"
                minW="109px"
                bgColor="white"
                borderColor="#2A2E3A"
                color="black"
                borderWidth="1px"
                me="4"
                onClick={() => {
                  onSubmit(emptyState(type));
                  setOpen(false);
                }}
              >
                {t('competitions.filters.clear')}
              </Button>
              <Button
                onClick={() => {
                  mixpanel?.track('Button Clicked', {button: 'competitions.filters.apply', filters: state});
                  onSubmit(state);
                  setOpen(false);
                }}
              >
                {t('competitions.filters.view_results')}
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

const Body = ({
  state,
  setState,
  ...props
}: {
  filterByType: FiltersProps['filterByType'];
  state: CompetitionFilters;
  setState: Dispatch<SetStateAction<CompetitionFilters>>;
}) => {
  const format = useIntl().formatNumber;

  const me = useMeQuery().data;
  const isTacticsEnabled = useRegionSettings().data?.settings.tactic_enabled;

  const competitionTypes = isTacticsEnabled ? (['fantasy', 'predictions'] as const) : (['fantasy'] as const);

  const [coinsRange, setCoinsRange] = [
    state.entry_fees,
    (range: [number, number]) => setState((old) => ({...old, entry_fees: range})),
  ];

  const [matchesCollapsed, setMatchesCollapsed] = useState(false);
  const isWithinUsersRange =
    !!me &&
    ((me.coins >= MAX_COINS && MAX_ENTRY_FEE === coinsRange[1] && coinsRange[0] === 0) ||
      (coinsRange[1] === me.coins && coinsRange[0] === 0));
  const [withinUserCoinsSwitch, setwithinUserCoinsSwitch] = useState(isWithinUsersRange);

  const leagues = useLeaguesQuery().data;

  const matches = (
    state.league_ids.length ? leagues?.filter((league) => state.league_ids.includes(league.id)) : leagues
  )?.flatMap((league) => league.upcoming_matches);

  const t = useTranslate();

  return (
    <Box flex="1" overflowY="scroll" overflowX="visible" sx={hideScrollBar} pt="2.5" px="4" id="filters-body">
      <Box borderBottom="1px" borderColor="#DFDFDF" mb="4">
        <Text fontSize="18" fontWeight="700" color="#131318" mb="4">
          {t('competitions.type')}
        </Text>
        <Flex gap="2" mb="4">
          {props.filterByType ? (
            competitionTypes.map((type) => (
              <FilterButton
                key={type}
                checked={state.type.includes(type)}
                onCheckedChange={(checked) =>
                  setState((old) => ({
                    ...old,
                    type: checked ? [...state.type, type] : state.type.filter((v) => v !== type),
                  }))
                }
              >
                {t(`competitions.filters.${type}`)}
              </FilterButton>
            ))
          ) : (
            <>
              <FilterButton
                checked={state.featured ?? false}
                onCheckedChange={(checked) => setState((old) => ({...old, featured: checked ? true : undefined}))}
              >
                {t('competitions.filters.featured')}
              </FilterButton>
              {me && (
                <FilterButton
                  checked={state.mine ?? false}
                  onCheckedChange={(checked) => setState((old) => ({...old, mine: checked ? true : undefined}))}
                >
                  {t('competitions.filters.my-competitions')}
                </FilterButton>
              )}
            </>
          )}
        </Flex>
      </Box>

      <Box borderBottom="1px" borderColor="#DFDFDF" mb="4" id="entry_fees">
        <Text fontSize="18" fontWeight="700" color="#131318" mb="4">
          {t('competition.modal.fees')}
        </Text>
        {me && (
          <Flex mb="18" justify="space-between">
            <Box>
              <Text>{t('competitions.filters.within_my_coins')}</Text>
              <Text color="#ABABAB" fontWeight="600" fontSize="14px">
                {format(me.coins)} {t('app.coins')}
              </Text>
            </Box>
            <Switch
              colorScheme="green"
              isChecked={withinUserCoinsSwitch && isWithinUsersRange}
              onChange={(e) => {
                if (e.target.checked) {
                  setwithinUserCoinsSwitch(true);
                  return setCoinsRange([0, me.coins > MAX_COINS ? MAX_ENTRY_FEE : me.coins]);
                }

                setwithinUserCoinsSwitch(false);
                setCoinsRange([0, MAX_ENTRY_FEE]);
              }}
            />
          </Flex>
        )}
        <RangeSlider value={coinsRange} onValueChange={setCoinsRange} />
      </Box>
      <Box borderBottom="1px" borderColor="#DFDFDF" mb="4">
        <Flex justify="space-between" mb="4">
          <Text fontSize="18" fontWeight="700" color="#131318" mb="4">
            {t('home.leagues')}
          </Text>
          <Text color="#ABABAB" fontSize="14px" fontWeight="600">
            {format(leagues?.length ?? 0)} {t('home.leagues')}
          </Text>
        </Flex>
        <Flex mb="18" gap="1" sx={hideScrollBar} overflowX="scroll">
          {leagues?.map((league) => (
            <FilterButton
              key={league.id}
              checked={state.league_ids.includes(league.id)}
              onCheckedChange={(checked) =>
                setState((state) => ({
                  ...state,
                  league_ids: checked
                    ? [...state.league_ids, league.id]
                    : state.league_ids.filter((l) => l !== league.id),
                }))
              }
            >
              {league.name}
            </FilterButton>
          ))}
        </Flex>
      </Box>

      <Flex justify="space-between" mb="4">
        <Box>
          <Text fontSize="18" fontWeight="700" color="#131318" mb="1">
            {t('competition.card.matches')}
          </Text>
          <Text color="#ABABAB" fontSize="14px" fontWeight="600">
            {format(matches?.length ?? 0)} {t('competition.card.matches')}
          </Text>
        </Box>
        <Button
          onClick={() => setMatchesCollapsed((old) => !old)}
          display="flex"
          h="40px"
          paddingLeft="2"
          paddingRight="2"
          variant="unstyled"
          borderWidth="1px"
          borderColor={!matchesCollapsed ? 'green.400' : '#DFDFDF'}
          bgColor={!matchesCollapsed ? '#5AC57B33' : 'white'}
        >
          <Text fontSize="12px" fontWeight="600" me="1" color={!matchesCollapsed ? 'green.400' : 'black'}>
            {t('competitions.filters.select_matches')}
          </Text>
          <Arrow
            transform="auto"
            rotate={!matchesCollapsed ? '180deg' : '0deg'}
            fill={!matchesCollapsed ? 'green.400' : '#2A2E3A'}
          />
        </Button>
      </Flex>
      <Collapse in={!matchesCollapsed}>
        <Flex gap="1.5" flexDir="column">
          {matches?.map((match) => (
            <MatchCheckbox
              match={match}
              key={match.id}
              checked={state.matches.includes(match.id)}
              onCheckedChange={(checked) =>
                setState((old) => ({
                  ...old,
                  matches: checked ? [...old.matches, match.id] : old.matches.filter((_match) => _match !== match.id),
                }))
              }
            />
          ))}
        </Flex>
      </Collapse>
    </Box>
  );
};

const Arrow = (props: IconProps) => (
  <Icon width="14px" height="14px" viewBox="0 0 14 14" {...props}>
    <path d="M7.00004 0.333252C10.6734 0.333252 13.6667 3.31992 13.6667 6.99992C13.6667 10.6733 10.6734 13.6666 7.00004 13.6666C3.32004 13.6666 0.333374 10.6733 0.333374 6.99992C0.333374 3.31992 3.32004 0.333252 7.00004 0.333252ZM5.04004 5.68658C4.84671 5.48658 4.52671 5.48659 4.33337 5.67992C4.13337 5.87992 4.13337 6.19325 4.33337 6.38658L6.64671 8.71325C6.74004 8.80658 6.86671 8.85992 7.00004 8.85992C7.13337 8.85992 7.26004 8.80658 7.35337 8.71325L9.66671 6.38658C9.76671 6.29325 9.81337 6.16659 9.81337 6.03992C9.81337 5.90659 9.76671 5.77992 9.66671 5.67992C9.46671 5.48659 9.15337 5.48658 8.96004 5.68658L7.00004 7.65325L5.04004 5.68658Z" />
  </Icon>
);

const FilterButton = ({children, ...props}: _Checkbox.CheckboxProps) => {
  return (
    <Checkbox.Root
      _checked={{
        color: '#2A2E3A',
        borderColor: '#2A2E3A',
        fontWeight: '700',
        borderWidth: '2px',
      }}
      borderWidth="1px"
      minW="max-content"
      rounded="14px"
      textTransform="capitalize"
      color="#8B8D9A"
      fontWeight="500"
      h="44px"
      borderColor="#DFDFDF"
      paddingRight="3.5"
      paddingLeft="3.5"
      {...props}
    >
      <Text w="max-content" color="inheirt">
        {children}
      </Text>
    </Checkbox.Root>
  );
};

const MatchCheckbox = ({match, ...props}: {match: MatchT} & _Checkbox.CheckboxProps) => {
  return (
    <Checkbox.Root
      display="flex"
      alignItems="center"
      height="72px"
      w="100%"
      _checked={{
        borderColor: 'green.400',
        borderWidth: '2px',
      }}
      borderColor={'#DFDFDF'}
      borderWidth={'1px'}
      py="2"
      px="2"
      borderRadius="12px"
      {...props}
    >
      <Center boxSize="6" borderColor="#DFDFDF" borderWidth="1px" borderRadius="6px" me="2">
        <Checkbox.Indicator>
          <Box boxSize="4" bgColor="green.400" borderRadius="4px" />
        </Checkbox.Indicator>
      </Center>
      <Box color="#586486" fontSize="12px" fontWeight="700" textAlign="start">
        <Box bgColor="#F3F6FF" mb="1" px="2" py="1" rounded="6px" w="max-content">
          {match.home_team.name}
        </Box>
        <Box bgColor="#F3F6FF" px="2" py="1" rounded="6px" w="max-content">
          {match.away_team.name}
        </Box>
      </Box>
    </Checkbox.Root>
  );
};
