import {api} from '@eksab/api';
import {useAccessToken, useQuery} from '@eksab/hooks';
import {Order} from '../types';

const queryKey = ['my-orders'];
const queryFn = () => api.get('v2/me/orders').then((res) => res.data.data);

export function useMyOrdersQuery() {
  const accessToken = useAccessToken().data;
  return useQuery<Order[]>(queryKey, queryFn, {enabled: !!accessToken});
}

useMyOrdersQuery.queryKey = queryKey;
useMyOrdersQuery.queryFn = queryFn;
