import {api} from '@eksab/api';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {Prize} from '../types';

const queryKey = (id: string) => ['prizes', id];
const queryFn = (id: number | string) => () => api.get(`v2/me/prizes/${id}`).then((res) => res.data.data);

export function usePrizeQuery(id: string) {
  return useQuery<Prize>(queryKey(id), queryFn(id));
}

usePrizeQuery.queryFn = queryFn;
usePrizeQuery.queryKey = queryKey;

export function useSetPrizeQueryData() {
  const queryClient = useQueryClient();
  const setPrizeQueryData = (id: string, prize: Prize) => queryClient.setQueryData(queryKey(id), prize);
  return setPrizeQueryData;
}
