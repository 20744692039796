import {useRouter} from 'next/router';
import {useQueryClient, UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useAccessToken, useQuery} from '@eksab/hooks';
import {Bet} from '../types';

type Params = {
  season: number;
};

export type SeasonGame = {
  id: number;
  match: MatchT;
  bets: Bet[];
  game_score_bet?: {
    id: number;
    active: boolean;
    active_from: string;
    active_to: string;
    points: {
      exact: string;
      partial: string;
    };
    current_user_prediction: null | {value: {away_goals: number; home_goals: number}};
  };
  state: 'LIVE' | 'NOT_STARTED' | 'UNKNOWN' | 'CALCULATING' | 'CALCULATED';
  current_user_points: number | null;
};

type QueryContext = 'participant' | 'viewer';

const queryKey = (params: Params, context: QueryContext = 'viewer') => ['matches', params.season, context];

const queryFn = (params: Params, isLoggedIn: boolean) =>
  api.get<{data: SeasonGame[]}>(`v2/games${isLoggedIn ? '/auth' : ''}`, {params}).then((res) =>
    res.data.data.map((game) => ({
      ...game,
      userPoints: game.current_user_points,
    })),
  );

export function useSeasonGamesQuery(
  params: Params,
  context: QueryContext,
  options?: UseQueryOptions<(SeasonGame & {userPoints: number | null})[]>,
) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const isLoggedIn = !!useAccessToken()?.data;
  const placeholderData = queryClient.getQueryData<(SeasonGame & {userPoints: number})[]>([
    ...queryKey(params, 'viewer'),
    router.locale,
  ]);

  return useQuery<(SeasonGame & {userPoints: number | null})[]>(
    queryKey(params, context),
    () => queryFn(params, isLoggedIn),
    {
      ...options,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      placeholderData,
    },
  );
}

// We only refetch this query in case of participants, because only participants mutate this server state
export function useRefetchSeasonGamesQuery() {
  const queryClient = useQueryClient();
  return (seasonId: number) => queryClient.refetchQueries(queryKey({season: seasonId}, 'participant'));
}

useSeasonGamesQuery.queryKey = queryKey;
useSeasonGamesQuery.queryFn = queryFn;
