import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useInvalidateMe} from '@eksab/hooks';
import {useInvalidateCompetition} from './useCompetitionQuery';
import {useInvalidateCompetitions} from './useCompetitionsQuery/useCompetitionsQuery';
import {useInvalidateMyCompetitions, useInvalidateMyPowers} from '@eksab/features/profile/hooks';
import {useInvalidateCheckSameLineupQuery} from './useCheckSameLineupQuery';

type Params = string;

type Return = {
  data: unknown;
};

const leaveCompetition = (slug: Params) =>
  api.post<Params, AxiosResponse<Return>>(`v2/competitions/${slug}/leave`).then((res) => res.data.data);

export const useCompetitionWithdraw = (options?: UseMutationOptions<Return['data'], AxiosError, Params>) => {
  const invalidateMe = useInvalidateMe();
  const invalidateCompetition = useInvalidateCompetition();
  const invalidateCompetitions = useInvalidateCompetitions();
  const invalidateMyCompetitions = useInvalidateMyCompetitions();
  const invalidateMyPowers = useInvalidateMyPowers();
  const invalidateCheckSameLineupQuery = useInvalidateCheckSameLineupQuery();
  return useMutation<Return['data'], AxiosError, Params>((slug) => leaveCompetition(slug), {
    ...options,
    onSuccess: (...params) => {
      options?.onSuccess?.(...params);
      invalidateMe();
      invalidateCompetition(params[1], 'user');
      invalidateCompetitions();
      invalidateMyCompetitions();
      invalidateMyPowers();
      invalidateCheckSameLineupQuery(params[1]);
    },
  });
};
