import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {useIntl} from 'react-intl';
import {motion, isValidMotionProp} from 'framer-motion';
import {
  chakra,
  ComponentWithAs,
  Box,
  Flex,
  Text,
  BoxProps,
  Button,
  Image,
  IconProps,
  Center,
  useBreakpointValue,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import {InfoIcon} from '@chakra-ui/icons';

import {useTranslate} from '@eksab/i18n';
import {Card} from '@eksab/components/Card';
import {useAccessToken} from '@eksab/hooks';
import {RocketIcon, PersonIcon, BallIcon} from '@eksab/assets/icons';

import {CoinsIcon} from './';
import {CompetitionTier} from './CompetitionTier';
import {useSetCompetitionQueryData} from '../../hooks/useCompetitionQuery';
import {type Competition, CompetitionType} from '../../types';

interface CompetitionCardProps extends BoxProps {
  competition: Competition;
  beingCreated?: boolean;
  prioritizeTypeOverState?: boolean;
  inGroups?: boolean;
}

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});

export function CompetitionCard({
  competition,
  beingCreated = false,
  prioritizeTypeOverState = false,
  inGroups,
  ...props
}: CompetitionCardProps) {
  const isLoggedIn = useAccessToken().isSuccess;
  const format = useIntl();
  const {push, asPath, query} = useRouter();
  const isMobile = useBreakpointValue([true, true, false]) ?? true;

  const t = useTranslate();
  const setCompetitionQueryData = useSetCompetitionQueryData();

  const formatDate = (date: string) =>
    format.formatDate(date, {
      day: 'numeric',
      month: 'short',
      year: undefined,
    });
  const formatNumber = (num: number) => format.formatNumber(num);

  const isFantasy = CompetitionType.FANTASY === competition.type;
  const competitionPath = `${inGroups ? `/groups/${query.groupId as string}` : ''}/${
    isFantasy ? 'fantasy' : 'predictions'
  }/${competition.id}/${competition.joined ? (isFantasy ? 'lineup' : 'matches') : ''}`;

  const maxParticipantsReached =
    !!competition.participants.max && competition.participants.max === competition.participants.current;

  const onCardClick = () => {
    if (beingCreated || !competition.id) return;

    mixpanel?.track('Button Clicked', {button: 'competition.join_new', source: asPath});

    setCompetitionQueryData(competition.id!, competition, isLoggedIn ? 'user' : 'guest');
    push(competitionPath);
  };

  let participantsFormatted = formatNumber(competition.participants.current);
  if (competition.participants.max) {
    participantsFormatted += ` / ${formatNumber(competition.participants.max)}`;
  }

  return (
    // @ts-ignore
    <ChakraBox
      p="3px"
      {...(competition.sponsors?.[0]
        ? {
            animate: {
              backgroundImage: `linear-gradient(360deg, 
              ${competition.sponsors?.[0].stroke_colors?.color_1 ?? '#000'} , 
              ${competition.sponsors?.[0].stroke_colors?.color_2})`,
            },
            transition: {duration: 3, repeatType: 'loop', repeatDelay: 0, repeat: Infinity, ease: 'linear'},
          }
        : {backgroundColor: 'black'})}
      rounded="md"
    >
      <Card
        display="flex"
        flexDir="column"
        position="relative"
        maxW="full"
        h="full"
        bg="black"
        overflow="hidden"
        cursor={beingCreated ? 'initial' : 'pointer'}
        onClick={onCardClick}
        {...props}
      >
        <Flex flex={1} flexDir="column" ps={[5, null, 6]} pe="4" pt={[2.5, null, 4]} pb="3">
          <Flex justify="space-between" gap="2">
            <Box>
              <Flex align="center">
                <Text
                  color="green.400"
                  fontSize="xs"
                  fontWeight="700"
                  lineHeight={[4, null, 5]}
                  textTransform="capitalize"
                >
                  {prioritizeTypeOverState
                    ? t(`app.${isFantasy ? 'fantasy' : 'predictions'}`)
                    : competition.featured
                      ? t(`competitions.filters.featured`)
                      : t(`competitions.filters.${competition.state}`)}
                </Text>

                <CompetitionTier tier={competition.tier} ms="2" />

                {competition.tier === 'flex' && (
                  <Popover trigger={isMobile ? 'click' : 'hover'} placement="auto">
                    <PopoverTrigger>
                      <InfoIcon
                        color="gray.100"
                        ms="-2"
                        zIndex={1}
                        width="5"
                        height="auto"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </PopoverTrigger>

                    <PopoverContent
                      bg="white"
                      borderColor="white"
                      shadow="lg"
                      _focusVisible={{}}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <PopoverArrow bg="white" />

                      <PopoverBody as="p" color="gray.700" fontSize="xs" fontWeight="600">
                        {t('competition.tier.flex.description', {
                          b: (chunks) => (
                            <Text as="span" fontWeight="600" color="inherit">
                              {chunks}
                            </Text>
                          ),
                          min: formatNumber(competition.participants.min!),
                        })}
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              </Flex>

              <Text color="gray.500" fontSize="xs" fontWeight="700" lineHeight={[4, null, 5]} mt={['px', null, -0.5]}>
                {competition.season.start && competition.season.end
                  ? t('competition.card.live-from-to', {
                      from: formatDate(competition.season.start),
                      to: formatDate(competition.season.end),
                    })
                  : '_'}
              </Text>
            </Box>

            <Flex gap="2" align="flex-start">
              {!!competition?.boostable && (
                <Center boxSize="36px" borderRadius="full" borderWidth="1px" bg="#2A2E3A" borderColor="#FB6B02">
                  <RocketIcon h="32px" w="auto" p="0.5" />
                </Center>
              )}

              {!beingCreated && competition.leagues?.length && competition.leagues[0].logo?.original && (
                <Image
                  title={competition.leagues[0].name}
                  src={competition.leagues[0].logo.original}
                  alt={competition.leagues[0].name}
                  boxSize="14"
                  objectFit="contain"
                  objectPosition="top right"
                />
              )}
            </Flex>
          </Flex>

          <CompetitionTitle competition={competition} />

          {competition.sponsors?.[0] && competition.sponsors[0].logo && (
            <Flex align="center" gap="1.5" h="8">
              <Text color="white" fontSize="sm" fontWeight="700">
                {t('competition.sponsored_by')}
              </Text>

              <Image
                src={competition.sponsors[0].logo.original}
                minW="auto"
                h="full"
                alt={competition.sponsors?.[0].name}
                objectFit="cover"
              />
            </Flex>
          )}
        </Flex>

        <Flex
          p={[4, null, '1.375rem']}
          pt={[6, null, 9]}
          flex={1}
          flexDir="column"
          justify="space-between"
          gap={[6, null, 7]}
          bg="white"
        >
          <Flex justify="space-between" gap="1" textAlign="center">
            <CompetitionCardItem
              icon={BallIcon}
              title={t('competition.card.matches')}
              value={formatNumber(competition.season.game_count)}
            />

            <CompetitionCardItem
              icon={CoinsIcon}
              title={t('competition.card.fee')}
              value={formatNumber(competition.entry_fee)}
            />

            <CompetitionCardItem
              icon={PersonIcon}
              title={t('competition.card.participants')}
              value={`${participantsFormatted}`}
            />
          </Flex>

          <NextLink href={beingCreated ? asPath : competitionPath} passHref legacyBehavior>
            <Button
              as="a"
              disabled={competition.state !== 'upcoming' || (maxParticipantsReached && !competition.joined)}
              w="full"
              h="clamp(2.5rem, 12vw, 3.125rem)"
              fontSize="min(1.125rem, 4.5vw)"
              variant={competition.joined ? 'outline' : 'solid'}
            >
              {!!competition.joined_friends && (
                <Text
                  pos="absolute"
                  top="-1.125rem"
                  align="center"
                  alignSelf="center"
                  fontSize="x-small"
                  fontWeight="600"
                  textTransform="initial"
                  color="gray.500"
                >
                  {t('competition.card.joined-friends', {count: formatNumber(competition.joined_friends)})}
                </Text>
              )}

              {t(`competition.card.cta${competition.joined ? '-joined' : ''}`)}
            </Button>
          </NextLink>
        </Flex>
      </Card>
    </ChakraBox>
  );
}

const CompetitionTitle = ({competition}: {competition: Competition}) => {
  if (!competition.has_custom_name)
    return (
      <Flex as="h3" flexDir="column" gap="0.5">
        <Text as="span" fontWeight="800" fontSize="2xl" color="white">
          {competition.structured_name.prize} {''}
        </Text>

        <Text as="span" fontWeight="700" fontSize="xl" color="gray.400">
          {competition.structured_name.winners}
        </Text>
      </Flex>
    );

  if (competition.subtitle)
    return (
      <Flex as="h3" flexDir="column" gap="0.5">
        <Text as="span" fontWeight="800" fontSize="2xl" color="white">
          {competition.name} {''}
        </Text>

        <Text as="span" fontWeight="700" fontSize="xl" color="gray.400">
          {competition.subtitle}
        </Text>
      </Flex>
    );

  return (
    <Flex as="h3" flex={1} align="center">
      <Text as="span" fontWeight="800" fontSize="2xl" color="white" noOfLines={2}>
        {competition.name}
      </Text>
    </Flex>
  );
};

interface CompetitionCardItemProps {
  icon: ComponentWithAs<'svg', IconProps>;
  title: string;
  value: string;
}

const CompetitionCardItem = ({icon: Icon, title, value}: CompetitionCardItemProps) => (
  <Flex flex={1} flexDir="column" align="center" justify="center">
    <Icon height="clamp(2.125rem, 10.5vw, 2.75rem)" mb={[1.5, null, 2.5]} />

    <Text fontWeight="800" fontSize="xl" lineHeight="sm">
      {value}
    </Text>

    <Text textTransform="capitalize" fontSize="sm">
      {title}
    </Text>
  </Flex>
);

const Skeleton = (props: {width?: string[]; height?: string}) => {
  const width = useBreakpointValue(props.width ?? ['100%']);

  return (
    <ChakraBox
      style={{width, height: props.height ?? 339, backgroundColor: '#f3f3f3', borderRadius: '0.625rem', flexShrink: 0}}
      h="full"
      // @ts-ignore
      transition={{repeat: Infinity, repeatType: 'reverse', duration: 1}}
      animate={{backgroundColor: '#ecebeb'}}
    />
  );
};

CompetitionCard.Skeleton = Skeleton;
