import {useMutation} from '@tanstack/react-query';

import {api} from '@eksab/api';

import {useClearNumberOfNewNotifications} from './useNotificationsInfiniteQuery';

export function useMarkNotificationsAsReadMutation() {
  const clearNewNotifications = useClearNumberOfNewNotifications();

  return useMutation(() => api.post('/v2/me/notifications/mark-as-read'), {
    onSuccess: () => {
      clearNewNotifications();
    },
  });
}
