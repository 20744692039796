import {AxiosError} from 'axios';
import {useRouter} from 'next/router';
import {useQueryClient, UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks/useQuery';

import type {Competition} from '../types';
import {useAccessToken} from '@eksab/hooks';

type QueryContext = 'user' | 'guest';

const queryKey = (slug: string | number, context: QueryContext = 'guest') => ['competition', `${slug}`, context];

const queryFn = (slug: string | number, isLoggedIn: boolean) =>
  api.get(`v2/competitions/${slug}${isLoggedIn ? '/auth' : ''}`).then((res) => res.data.data);

export function useCompetitionQuery(slug: string | undefined, options?: UseQueryOptions<Competition, AxiosError>) {
  const isLoggedIn = useAccessToken().isSuccess;
  const queryClient = useQueryClient();

  const locale = useRouter().locale;
  const placeholderData = queryClient.getQueryData<Competition>([...queryKey(slug!, 'guest'), locale]);
  const competitionQuery = useQuery<Competition, AxiosError>(
    queryKey(slug!, isLoggedIn ? 'user' : 'guest'),
    () => queryFn(slug!, isLoggedIn),
    {
      ...options,
      placeholderData,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      enabled: (options?.enabled ?? true) && !!slug,
    },
  );

  return competitionQuery;
}

export function useSetCompetitionQueryData() {
  const {locale = 'en'} = useRouter();
  const queryClient = useQueryClient();

  const setCompetitionQueryData = (
    slug: string | number,
    competition: Partial<Competition>,
    context: QueryContext = 'guest',
  ) => {
    const _queryKey = [...queryKey(slug, context), locale];
    queryClient.setQueryData<Competition>(_queryKey, (old) => ({...old!, ...competition}), {
      updatedAt: new Date().getTime(),
    });
  };

  return setCompetitionQueryData;
}

export function usePrefetchCompetition() {
  const isLoggedIn = !!useAccessToken().data;
  const {locale = 'en'} = useRouter();
  const queryClient = useQueryClient();
  return (slug: string | number, context: QueryContext) =>
    queryClient.prefetchQuery<Competition>([...queryKey(slug, context), locale], () => queryFn(slug, isLoggedIn));
}

export function useInvalidateCompetition() {
  const queryClient = useQueryClient();
  return (slug: string | number, context: QueryContext) =>
    queryClient.invalidateQueries<Competition>([...queryKey(slug, context)]);
}

useCompetitionQuery.queryFn = queryFn;
useCompetitionQuery.queryKey = queryKey;
