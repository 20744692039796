import {useInfiniteQuery, useQueryClient} from '@tanstack/react-query';

import {api} from '@eksab/api';

import type {Competition} from '@eksab/features/competition/types';
import {MyCompetitionsFilter} from '../types';

type Response = {
  data: Competition[];
  meta: {current_page: number; last_page: number};
};

const queryKey = (type: Competition['type'], state?: MyCompetitionsFilter) => ['my-competitions', type, state];

const queryFn = (page: number, type: Competition['type'], state?: MyCompetitionsFilter) =>
  api
    .get('v2/competitions/auth', {
      params: {
        page,
        type,
        mine: true,
        state: state === 'cancelled' ? undefined : state,
        'include-cancelled': state === 'cancelled' ? true : undefined,
      },
    })
    .then((res) => res.data as Response);

export const useMyCompetitionsInfiniteQuery = (type: Competition['type'], state?: MyCompetitionsFilter) =>
  useInfiniteQuery(queryKey(type, state), ({pageParam = 1}) => queryFn(pageParam, type, state), {
    getNextPageParam: ({meta: {current_page, last_page}}) => (current_page < last_page ? current_page + 1 : undefined),
  });

export function useInvalidateMyCompetitions() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries<Competition>(['my-competitions']);
}

useMyCompetitionsInfiniteQuery.queryKey = queryKey;
useMyCompetitionsInfiniteQuery.queryFn = queryFn;
