import {AxiosError} from 'axios';
import {
  QueryFunctionContext,
  QueryKey,
  useQueryClient,
  useInfiniteQuery,
  InfiniteData,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import {useRouter} from 'next/router';

import {api} from '@eksab/api';
import {useAccessToken} from '@eksab/hooks/useAccessToken';
import {Competition, CompetitionType} from '../../types';
import {CompetitionFilters} from '../../components/filters/Filters';
import {getCountry, getLanguage} from '@eksab/util';
import {useGetRegionSettingsQueryData} from '@eksab/hooks';

type PaginatedCompetitions = {
  data: Competition[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
  };
};
const queryKey = (filters: Partial<CompetitionFilters>, context: 'guest' | 'user' = 'guest', locale: Locale) => [
  'competitions',
  filters,
  context,
  locale,
];

const queryFn = (context: QueryFunctionContext<QueryKey>, params: Partial<CompetitionFilters>, isLoggedIn: boolean) => {
  const locale = context.queryKey[context.queryKey.length - 1] as string;

  const {type: competitionType, ...rest} = params;

  const type = context.meta?.isTacticsEnabled
    ? !competitionType || competitionType.length === 0 || competitionType.length === 2
      ? undefined
      : competitionType[0] === 'fantasy'
        ? CompetitionType.FANTASY
        : CompetitionType.PREDICTION
    : CompetitionType.FANTASY;

  return api
    .get(`/v2/competitions${isLoggedIn ? '/auth' : ''}`, {
      params: {
        'expected-first': true,
        'include-matches': 1,
        state: 'upcoming',
        page: context.pageParam ?? 1,
        per_page: 9,
        type,
        ...rest,
      },
      headers: {
        'X-Locale': getLanguage(locale),
        'X-REGION': getCountry(locale),
      },
    })
    .then((res) => res.data);
};

export const useCompetitionsQuery = (
  params: Partial<CompetitionFilters>,
  options?: UseInfiniteQueryOptions<PaginatedCompetitions, AxiosError>,
) => {
  const isLoggedIn = useAccessToken().isSuccess;
  const queryClient = useQueryClient();
  const getRegionSettingsData = useGetRegionSettingsQueryData();
  const locale = useRouter().locale;
  const placeholderData = queryClient.getQueryData<InfiniteData<PaginatedCompetitions>>([
    ...queryKey(params, 'guest', locale),
  ]);

  return useInfiniteQuery<PaginatedCompetitions, AxiosError>(
    queryKey(params, isLoggedIn ? 'user' : 'guest', locale),
    (context) => queryFn(context, params, isLoggedIn),
    {
      ...options,
      getNextPageParam: ({meta}) =>
        meta.current_page < meta.total / meta.per_page ? meta.current_page + 1 : undefined,
      placeholderData,
      meta: {
        ...options?.meta,
        isTacticsEnabled: getRegionSettingsData()?.settings.tactic_enabled ?? true,
      },
    },
  );
};

export function useInvalidateCompetitions() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries<Competition>(['competitions']);
}

useCompetitionsQuery.queryFn = queryFn;
useCompetitionsQuery.queryKey = queryKey;
