import {AxiosError} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useInvalidateMyCompetitions} from '@eksab/features/profile/hooks';
import {useInvalidateMe} from '@eksab/hooks';
import {useInvalidateCompetitions} from './useCompetitionsQuery/useCompetitionsQuery';

interface MultiEntryRequestParams {
  competition_ids: number[];
}

export function useMultiEntryMutation(
  competitionID: string,
  options?: UseMutationOptions<unknown, AxiosError, MultiEntryRequestParams>,
) {
  const invalidateMe = useInvalidateMe();

  const invalidateCompetitions = useInvalidateCompetitions();
  const invalidateMyCompetitions = useInvalidateMyCompetitions();

  return useMutation<any, AxiosError, MultiEntryRequestParams>(
    (params) => api.post<MultiEntryRequestParams>(`v2/competitions/${competitionID}/multi-entry`, params),
    {
      ...options,
      onSuccess: (...params) => {
        invalidateMe();
        invalidateCompetitions();
        invalidateMyCompetitions();
        options?.onSuccess?.(...params);
      },
    },
  );
}
