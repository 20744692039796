import {useQuery, useQueryClient} from '@tanstack/react-query';

import {useAccessToken} from '@eksab/hooks';

export type PaymentCard = {id: number; token: string; masked_pan: string; card_type: 'MasterCard' | 'Visa'};

const queryKey = ['cards'];

const queryFn = () =>
  new Promise<PaymentCard[]>((resolve) => {
    resolve([]);
  });

export function useMyCardsQuery() {
  const accessToken = useAccessToken().data;
  return useQuery<PaymentCard[]>(queryKey, queryFn, {enabled: !!accessToken});
}

useMyCardsQuery.queryKey = queryKey;
useMyCardsQuery.queryFn = queryFn;

export function useInvalidateMyCards() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries<PaymentCard[]>(queryKey);
}
