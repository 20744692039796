import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import * as cache from '@eksab/cache';
import {useFetchMe} from '@eksab/hooks';

type Params = {
  phone_number: string;
  otp: string;
};

export const useChangePhoneNumberMutation = (options?: UseMutationOptions<void, AxiosError, Params>) => {
  const fetchMe = useFetchMe();

  return useMutation<void, AxiosError, Params>(
    (params) =>
      api.post<Params, AxiosResponse<void>>('/v2/profile/phone-number/change', params).then((res) => res.data),
    {
      ...options,
      onSuccess: async (...params) => {
        try {
          const me = await fetchMe();
          cache.setMe(me);

          options?.onSuccess?.(...params);
        } catch (e) {
          console.error(e);
        }
      },
    },
  );
};
